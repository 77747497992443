<template>
	<div>
		<hero image="careerPath.jpg" class="career-path">
			<h1 class="mb-4">Where might you
				<span class="moving-underline" v-in-viewport>
					go from here
				</span>
			</h1>
			<h3 class="text-grey-2">There's never been a better time to launch your career in the exciting world of supply chain logistics.</h3>
		</hero>
		<b-container>
			<b-row>
				<b-col md="10"><heading class="mt-6 mb-3">Keeping the World Moving</heading></b-col>
			</b-row>
			<b-row class="mb-6">
				<b-col md="5" class="outer mb-3 mb-md-0">
					<div class="top position-relative h-100" style="background-image:url(/assets/img/video/4.jpg); min-height:200px"><img src="/assets/img/icons/play.svg" class="absolute-center play-icon visible"></div>
				</b-col>
				<b-col md="5"><p>
					The global supply chain industry is dynamic, fast paced and continually evolving. Take a look at this video about how freight forwarding works and you will see what we mean.
				</p><p>
	It's an industry that offers countless opportunities for professionals seeking an exciting and fulfilling career. Professionals that thrive on teamwork, problem solving and personal growth and development.</p><p>If that sounds like you, a career in supply chain logistics may be your perfect match.</p>
	</b-col>
			</b-row>
			<b-row class="mb-6">
				<b-col md="10">
					<heading>Why Choose Us?</heading>
					<p class="font-size-lg">
						At WiseTech Academy, we offer courses and training that provide you with the skills and knowledge you need to kickstart your career in supply chain logistics.
					</p>
					<p class="font-size-lg">
						Whether it's learning about the movement and distribution of cargo, how to consolidate freight, or how to market goods and services internationally, our industry experts can help you navigate the right path through flexible, affordable and high quality online education.
					</p>
					<b-button>Learn More</b-button>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<h2 class="mb-3" id="insights">Why would you want to work in logistics?</h2>
				</b-col>
			</b-row>
			<!-- <b-card-group deck>
				<b-card title="US $10 tril" border-variant="teal" class="rounded">
					<b-card-text class="font-size-xs">value of global merchandise exports by WTO members</b-card-text>
				</b-card>
				<b-card title="US $17 tril" border-variant="teal">
					<b-card-text class="font-size-xs">EU logistics market size</b-card-text>
				</b-card>
				<b-card title="US $1 tril" border-variant="teal">
					<b-card-text class="font-size-xs">expected value of 2020 cross border e‑commerce shipments</b-card-text>
				</b-card>
				<b-card title="US $10 tril" border-variant="teal">
					<b-card-text class="font-size-xs">expected value of 2020 cross border e‑commerce shipments</b-card-text>
				</b-card>
			</b-card-group> -->
			<b-row class="mb-6">
				<info-box title="US $10 tril" text="value of global merchandise exports by WTO members"></info-box>
				<info-box title="US $17 tril" text="EU logistics market size"></info-box>
				<info-box title="US $1 tril" text="expected value of 2020 cross border e‑commerce shipments"></info-box>
				<info-box title="US $10 tril" text="expected value of 2020 cross border e‑commerce shipments"></info-box>
			</b-row>
			<b-row id="stories">
				<b-col>
					<h2 class="mb-2">Be inspired</h2>
					<p class="font-size-lg mb-4">Hear from people forging successful careers in the global supply chain industry.</p>
				</b-col>
			</b-row>
		</b-container>
		<div class="bg-light">
			<b-container>
				<b-row no-gutters class="bg-primary mb-4 has-moving-arrow outer" @click="openFeaturedVideo">
					<b-col md="6"><div class="top position-relative inspired-video" :style="`background-image:url(${featuredVideo.img})`"><img src="/assets/img/icons/play.svg" class="absolute-center play-icon"></div></b-col>
					<b-col class="text-white py-4" cols="10" md="6">
						<h1 class="text-white px-sm-4">{{featuredVideo.title}}</h1>
						<h3 class="text-grey-2 pl-sm-4 pr-sm-5 mb-3">"{{featuredVideo.body}}"</h3>
						<span class="moving-arrow visible-arrow btn-xs px-sm-4">WATCH NOW</span>
					</b-col>
				</b-row>
			</b-container> 
		</div>
		<video-carousel class="mb-6" trim @trimmed="featuredVideo = $event" />
		<b-container>
			<b-row class="mb-6">
				<b-col cols="10" md="6" class="text-center text-sm-left">
					<span class="h2 quote">Fast track your career in the global supply 
chain with WiseTech Academy</span>
				</b-col>
			</b-row>
			<b-row id="jobs">
				<b-col>
					<heading class="pb-3">Example Careers</heading>
				</b-col>
			</b-row>
			<b-row class="mb-6">
				<b-col cols="12" md="6">
					<accordian id="career-1" :items="careers[0]" group="careers"></accordian>
				</b-col>
				<b-col cols="12" md="6">
					<accordian id="career-2" :items="careers[1]" group="careers"></accordian>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<hr class="pb-5">
					<heading class="pb-3">Where Can I Find More Information?</heading>
				</b-col>
			</b-row>
			<b-row class="mb-5">
				<b-col sm="6" class="mb-4 mb-md-0">
					<p>
						Industry bodies and associations are a reliable information source and a great place to start your research. Here are some of the key industry bodies in Australia:
					</p>
					<a href="https://www.afif.asn.au/" target="_blank">Australian Federation of International Forwarders (AFIF)</a><br>
					<a href="https://www.ftalliance.com.au/" target="_blank">Freight and Trade Alliance (FTA)</a><br>
					<a href="https://shippingaustralia.com.au/" target="_blank">Shipping Australia</a>
				</b-col>
				<b-col sm="6">
					<p>
						Some recruiting companies have specialized skills placing people in the supply chain logistics industry. In Australia, some examples are:
					</p>
					<a href="http://www.frmstaff.com.au/" target="_blank">Frontline Resources Management</a><br>
					<a href="https://futureforce.com.au/" target="_blank">Future Force</a><br>
					<a href="https://www.sfsolutions.com.au/" target="_blank">Stone Freight Solutions</a><br>
					<a href="https://www.impexpersonnel.com.au/" target="_blank">Impex Personnel</a><br>
					<a href="https://insyncpersonnel.com.au/" target="_blank">Insync Personnel</a>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<hr class="pt-5">
				</b-col>
			</b-row>
			<b-row>
				<b-col cols="10" sm="12">
					<heading class="pb-3">Industry Insights</heading>
				</b-col>
			</b-row>
			<b-row class="mb-6">
				<b-col cols="10" sm="4">
					<h4 class="text-dark font-weight-bold">Ecommerce</h4>
					<p class="font-size-sm">Cross-border ecommerce shipments worldwide could rise to $1 trillion by 2020.</p>
				</b-col>
				<b-col cols="10" sm="4">
					<h4 class="text-dark font-weight-bold">Spending</h4>
					<p class="font-size-sm">The Global Logistics industry is set to spend over USD 10 trillion in 2020.</p>
				</b-col>
				<b-col cols="10" sm="4">
					<h4 class="text-dark font-weight-bold">Movement</h4>
					<p class="font-size-sm">Each day, $1.2 billion worth of trade moves through Australian ports, including 8 million containers and 20 trillion liters of fuel.</p>
				</b-col>
			</b-row>
		</b-container>
		<featured-courses class="mb-6"/>
	</div>
</template>
<script>
import { InfoBox } from '@/components/ui'
// import { BCard, BCardGroup, BCardText } from 'bootstrap-vue'

export default {
	components: {
		'info-box': InfoBox
		// 'b-card': BCard,
		// 'b-card-group': BCardGroup,
		// 'b-card-text': BCardText
	},
	data() {
		return {
			featuredVideo: {}
		}
	},
	computed: {
		topVideo() {
			return {
				img: '/assets/img/video/4.jpg', title: 'Freight Forwarding Basics', token: '1aab9808e3d91f74af9f90cfeac06d31', videoId: '62142164'
			}
		},
		careers() {
			return [[
				{title: 'Customs Broker', content: 'Acting on behalf of their clients (usually importers), a licensed customs broker manages the border clearance process for cargo. This includes ensuring compliance with the laws of different countries, as well as advising clients on a range of technical matters such as biosecurity and importing permits.'},
				{title: 'International Freight Forwarder', content: "An international freight forwarder is often called an 'Architect of Transport' because of the range of services they provide, including cargo tracking, inland transportation, preparation of shipping and export documents, warehousing, booking cargo space, negotiating freight charges, booking freight with designated carriers, freight consolidation, and cargo insurance."},
				{title: 'Importer', content: "Also known as the 'buyer' in an international trade transaction, an importer is at the receiving end of the global supply chain whereby they take delivery of merchandise or commodities imported from a foreign country for use, sale or processing in the receiving country."}
			], [
				{title: 'Exporter', content: "Also known as the 'seller' in an international trade transaction, an exporter is an individual or company that sends merchandise or commodities from one country to another for use or sale."},
				{title: 'Third Party Logistics Forwarder', content: "Also known as a '3PL' or 'TPL' forwarder, this description applies when a Freight Forwarder provides flexible solutions by moving goods using modes of transport that are not owned by their own organisation."}
			]]
		}

	},
	methods: {
		openFeaturedVideo() {
			this.$store.commit('video/setProps', {videoId: this.featuredVideo.videoId, token: this.featuredVideo.token})
		}
	}
}
</script>

<style lang="scss">
.inspired-video{
	height: 100%;
	min-height: 333px;
	@include media-breakpoint-down(sm){
		height: 40vw;
		min-height: 200px;
	}
}
@include media-breakpoint-down(lg){
	h1{
		font-size: $h3-font-size;
	}
	h3{
		font-size: $h4-font-size;
	}
}
</style>